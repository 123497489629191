import React, { useEffect, useMemo } from "react";
import style from "./Projects.module.css";
import { Header } from "../../components/Header/header";
import { Footer } from "../../components/Footer/footer";
import { SideBar } from "../../components/SideBar/SideBar";
import { useState } from "react";
import { useGetDoc } from '../../Hooks/useGetDocs'
import { useProjectFilterCollection } from '../useProjectFilterCollection';
import { GridProjects } from "../../components/Grid/Grid";
import { useLocalStorage } from "../../Hooks/useLocalStorage";



export const Projects = () => {

  const { docs } = useGetDoc('projects')
  const { projectFilterCollection } = useProjectFilterCollection()

  const [category, setCategory] = useLocalStorage('all', 'cat')
  // const [category, setCategory] = useState('all')
  const [filtered, setFiltered] = useState([])

  const handleChangeCategory = e => {
    const { id } = e.target;
    setCategory(id)
  }

  useEffect(() => {
    category !== 'all'
      ? setFiltered(docs.filter(project => project.category.includes(category)))
      : setFiltered(docs)
  }, [category, docs, projectFilterCollection]);

  return (
    <>

      <Header />
      <main className={style.main}>
        <SideBar
          projectFilterCollection={projectFilterCollection}
          onChange={handleChangeCategory}
        />
        <div className={style.container}>
          <GridProjects
            columns={{ mobile: 2, tablet: 3, desktop: 3 }}
            gap={{ mobile: 12, tablet: 30, desktop: 40 }}
            data={filtered}
            category={category}
            categories={projectFilterCollection}
          />
        </div>
      </main>
      <Footer />
    </>
  )
}


