import React from 'react';
import { Header } from '../../components/Header/header';
import { Footer } from '../../components/Footer/footer';

import { useParams } from 'react-router-dom';
import style from './ProjectSinglepage.module.css'
import { useProjectData } from "../useProjectData";
import { ColumnSinglepage, GridSinglepage } from '../../components/Grid/Grid';
import { useGetDoc } from '../../Hooks/useGetDocs'


export const ProjectSinglepage = () => {

    const { docs } = useGetDoc('projects')

    const { link } = useParams()
    const project = docs.find((project) => project.link === link) // возвращает объект удовл условиям



    return (
        <>

            <Header />

            <main className={style.main}>
                <div className={style.content}>
                    <div className={style.text}>
                        <div className={style.about} >
                            <h6> О проекте</h6>
                            <p>{project?.about}</p>
                        </div>
                        <div className={style.client}>
                            <h6>Клиент</h6>
                            <div className={style.exlink}>

                                <a
                                    href={project?.exLink}
                                    target='_blank'>
                                    {project?.client}
                                    {project?.exLink
                                        ? <img src="/img/icon/arrowExLink.svg" />
                                        : null
                                    }
                                </a>

                            </div>
                        </div>
                    </div>
                    <div className={style.images}>

                        {project?.oneColumn
                            ? <ColumnSinglepage data={project?.images} />
                            : <GridSinglepage
                                columns={{ mobile: 1, tablet: 2, desktop: 2 }}
                                gap={{ mobile: 12, tablet: 30, desktop: 48 }}
                                data={project?.images}
                            />
                        }


                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
};