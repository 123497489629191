import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import style from './header.module.css'


export const Header = () => {
    return (

        <header>
            <div className={style.container}>
                <div className={style.header} >
                    <Link to="/" className={style.logoContainer}>
                        <img
                            className={style.logoDesktop}
                            src="/GraphicDigger_logo.png"
                        />
                        <img
                            className={style.logoMobile}
                            src="LogoD.svg"
                        />
                    </Link>

                    <div className={style.navBar}>

                        <div className={style.navBarUnits}>
                            <Link to="/login" className={style.navBarUnit} ></Link>
                            {/* <Link to="/admin" className={style.navBarUnit} >admin</Link> */}
                            <Link to="/contact" className={style.navBarUnit} >Связь</Link>
                        </div>
                        <BurgerButton />
                    </div>
                </div>
            </div >

        </header >
    );
};


const BurgerButton = () => {

    const [active, setActive] = useState(false)

    return (
        <>
            <img className={active ? `${style.burgerButton} ${style.active}` : style.burgerButton}
                src='/img/icon/burger_menu.svg'
                onClick={() => setActive(!active)}
            />
            {active}
            <BurgerMenu active={active} setActive={setActive} />
        </>
    );
};

const BurgerMenu = ({ active, setActive }) => {

    return (
        <>
            <div className={active ? `${style.burgerNav} ${style.active}` : style.burgerNav} onClick={() => setActive(false)}>
                <div className={active ? `${style.burgerNavLinks} ${style.active}` : style.burgerNavLinks}>
                    <a href='/portfolio'></a>
                    <a href='/contact'></a>
                </div>
                <div className={active ? `${style.backgroundBurgerNav} ${style.active}` : style.backgroundBurgerNav}></div>
            </div>
        </>
    );
};



