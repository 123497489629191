import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useNavigate } from 'react-router';
import { auth } from '../firebase-config'


import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    sendEmailVerification,
    sendPasswordResetEmail,
} from 'firebase/auth'


const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {

    const [user, setUser] = useState(null)

    const navigate = useNavigate()

    const authGoogle = () => {
        const googleAuthProvider = new GoogleAuthProvider()
        return signInWithPopup(auth, googleAuthProvider)
    }

    const signUp = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    const logIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logOut = (email, password) => {
        return signOut(auth)
    }

    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    }
    const verificationEmail = () => {
        return sendEmailVerification(user)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => { // наблюдатель
            setUser(currentUser) // получаем текущего пользователя
        })
        return () => {
            unsubscribe()
        }
    }, [])

    const value = {

        user,
        signUp,
        logIn,
        logOut,
        authGoogle,
        resetPassword,

    }

    return (
        <AuthContext.Provider
            value={value}>
            {children}
        </AuthContext.Provider>
    )
};

export const useAuth = () => {
    return useContext(AuthContext)
}