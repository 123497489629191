import React, { useEffect } from 'react';
import { Header } from '../components/Header/header';
import style from './Admin.module.css'
import { AddProductForm } from '../components/Form/formAddProduct/AddProductForm';
import { useProjectData } from '../Projects/useProjectData';
import { useProjectFilterCollection } from '../Projects/useProjectFilterCollection';
import Masonry from 'react-smart-masonry'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useGetDoc } from '../Hooks/useGetDocs';
import FirebaseData from '../Firebase/FirebaseData';


export const Admin = () => {

    const [projects, setProjects] = useState([])
    const [docId, setDocId] = useState('')

    const getProject = async () => {
        const data = await FirebaseData.getAllProjects()
        setProjects(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    useEffect(() => {
        getProject()
    }, []);

    const handlerDeleteProject = async (id) => {
        await FirebaseData.deleteProject(id)
        getProject()
    }

    const hundlerGetProjectId = (id) => {
        console.log('передаю id', id)
        setDocId(id)
    }


    return (
        <>
            <Header />
            <main className={style.main}>
                <div className={style.container}>
                    <div className={style.form}>
                        <AddProductForm docId={docId} setDocId={setDocId} getProject={getProject} />
                    </div>
                    <div className={style.masonryContainer}>
                        <div className={style.masonry}>

                            <div className={style.content} >

                            </div>

                            <Masonry columns={3} gap={30}>
                                {projects?.map((project) => {
                                    return <>
                                        <img src="/img/icon/DeleteDoc.svg" className={style.delete} onClick ={(e)=> handlerDeleteProject(project.id)} />
                                        {/* <img src='/img/icon/EditDoc.svg' className={style.edit} onClick={(e) => hundlerGetProjectId(project.id)} /> */}
                                        <Link to={`${project?.link}`} key={project?.id}>
                                            <div className={style.cover}>
                                                <img src={project?.cover} />
                                            </div>
                                        </Link>

                                    </>
                                }
                                )}
                            </Masonry>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
};
