import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Masonry from 'react-smart-masonry'
import { ModalImage } from '../Modal/Modal';
import style from './grid.module.css'


export const GridProjects = ({ columns, gap, data, category, categories }) => {

    const breakpoints = { mobile: 360, tablet: 768, desktop: 1280 };
    const linkCategory = categories.find(unit => unit.link === category)

    return (
        <div className={style.contents}>
            <Masonry
                columns={columns}
                gap={gap}
                breakpoints={breakpoints}
                reverse={true}
            >
                {data.map((project) => (
                    <Link
                        to={`${linkCategory?.link}/${project?.link}`}
                        key={project?.id}>
                        <div className={style.img}>
                            <img src={project?.cover} />
                        </div>
                    </Link>
                )
                )}
            </Masonry>
        </div>

    );
};

export const GridSinglepage = ({ columns, gap, data }) => {

    const breakpoints = { mobile: 360, tablet: 768, desktop: 1280 };

    return (
        <div className={style.contents}>
            <Masonry
                columns={columns}
                gap={gap}
                breakpoints={breakpoints}
                reverse={true}
            >
                {data?.map((url, index) => {
                    return <div className={style.img}>
                        <img
                            src={url}
                            key={index} />
                    </div>
                })
                }
            </Masonry>
        </div>

    );
};

export const ColumnSinglepage = ({ data }) => {

    return (
        <div className={style.contents}>
            {data.map((url, index) => {
                return <div className={`${style.img} ${style.oneColumn}`} key={index}>
                    <img src={url} />
                </div>
            })
            }
        </div>

    );
};



export const ProjectGridModal = ({ columns, gap, autoArrange, data, breakpoints }) => {

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    return (
        <>
            {openModal &&
                <ModalImage data={data} slideNumber={slideNumber} setSlideNumber={setSlideNumber} openModal={openModal} setOpenModal={setOpenModal}>
                    <div className={style.fullScreenImage}>
                        <img src={data[slideNumber].cover} />
                    </div>
                </ModalImage>
            }
            <Masonry
                breakpoints={breakpoints}
                columns={columns}
                gap={gap}
                autoArrange={autoArrange}
            >
                {data.map((project, index) => {
                    return (
                        <div
                            className={style.cover}
                            key={index}
                            onClick={() => handleOpenModal(index)}
                        >
                            <img src={project.cover} />
                        </div>)
                }
                )}
            </Masonry>
        </>
    );
};

// {data.map((project) => {
//     return <Link to={`${linkCategory?.link}/${project?.link}`} key={project?.id}
//     >
//         <div className={style.cover}>
//             <img src={project?.cover} />
//         </div>
//     </Link>
// }
// )}