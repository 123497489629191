
import React, { useRef } from 'react';
import style from './unitForm.module.css'
import { useToggle } from '../../../Hooks/useToggle'
import { NavLink } from 'react-router-dom';



export const Input = ({ placeholder, onChange, value, id, ...inputProps }) => {


    return (

        <input
            {...inputProps}
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />

    );
};

export const UploadFile = ({ onChange, value, url, ...inputProps }) => {

    const fileRef = useRef()
    const handleClick = (() => fileRef.current.click())

    return (
        <>
            <div className={style.cover}>
                <input
                    type="file"
                    className={style.hidden}
                    onChange={onChange}
                    ref={fileRef}
                />
                <div className={style.addCover} onClick={handleClick}>
                    <img src={url || "/img/icon/addCover.png"} />
                </div>
            </div>

        </>

    );
};

export const UploadFiles = ({ onChange, urls, images, setImages, deleteImages, ...inputProps }) => {

    const imagesRef = useRef()
    const handleClick = (() => imagesRef.current.click())

    return (
        <>
            <div className={style.file}>

                <input className={style.hidden}
                    ref={imagesRef}
                    type="file"
                    onChange={onChange}
                    multiple
                />
                <div className={style.buttonUploadFile} onClick={handleClick}>
                    <img src="/img/icon/AddDoc.svg" />
                    <p>Картинки проекта</p>
                </div>
                {images &&
                    images.map(file =>

                        <div className={style.filename} key={file.id}>
                            <div className={style.line}></div>
                            <div className={style.name}>{file.name}</div>
                            <img
                                src="/img/icon/DeleteDoc.svg"
                                onClick={() => setImages(images.filter(unit => unit !== file))} />
                        </div>
                    )
                }
            </div>

        </>

    );
};



export const CheckboxButton = ({ value, onChange, submitted, id }) => {

    const refCheckbox = useRef()
    const handleRefCheckbox = () => { refCheckbox.current.click() }
    const [checkbox, setCheckbox] = useToggle(false)


    return (
        <>
            <input
                id={id}
                type="checkbox"
                className={style.hidden}
                ref={refCheckbox}
                onClick={setCheckbox}
                onChange={onChange}
                value={value}
            />
            <div
                className={checkbox
                    ? `${style.btnCheck} ${style.active}`
                    : style.btnCheck}
                onClick={handleRefCheckbox}
            >{value}
            </div>

        </>
    );
};

export const Checkbox = ({label, id, checked, onChange}) => {


    return (
        <div className={style.checkbox}>
            <label htmlFor={id} className={style.checkLabel}>
                <input className={style.hiddenCheckbox}
                    id={id}
                    checked={checked}
                    type='checkbox'
                    onChange={onChange}
                />
                <span className={style.newCheckbox} ></span>
                {label}
            </label>

        </div>
    );
};


export const Radio = ({ onChange, value, id, name, ref, checked, link, ...inputProps }) => {

    const refRadio = useRef()
    const handleRefRadio = () => { refRadio.current.click() }

    return (
        <>
            <input
                {...inputProps}
                className={style.hidden}
                type="radio"
                onChange={onChange}
                value={value}
                name={name}
                ref={refRadio}
                id={id}
            />
            {link
                ?
                <>
                    <NavLink
                        to={link}
                        onClick={handleRefRadio}
                        className={({ isActive }) => isActive ? `${style.unitActive}` : `${style.unit}`}
                    >

                        <p className={style.unitText}>{value}</p>
                    </NavLink>
                </>
                : null}

        </>

    );
};
