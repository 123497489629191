

export const dataID = [
    // savie: 
    {
        id: Date.now(),
        cover: '/img/portfolio/identity/savie/cover.jpg',
        images: 'portfolio/identity/savie',
        title: 'Стиль для салона красоты в Турции',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'savie',

    },

    // educat: 
    {
        id: Date.now(),
        cover: '/img/portfolio/identity/educat/0000cover.gif',
        images: 'portfolio/identity/educat-character',
        title: 'educat.courses',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'educat',
    },

    // transsinergy: 
    {
        id: Date.now(),
        cover: '/img/portfolio/identity/trans_sinergy/cover.jpg',
        images: 'portfolio/identity/transsinergy',
        title: 'Транс Синергия',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'transsinergy',

    },

    // bumblebee: 
    {
        id: Date.now(),
        cover: '/img/portfolio/identity/bumblebee/cover.jpg',
        images: 'portfolio/identity/bumblebee',
        title: 'Bumblebee',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'bumblebee',

    },
    // educat1: 
    {
        id: Date.now(),
        cover: '/img/portfolio/identity/educat_v1/educat1_4.jpg',
        images: 'portfolio/identity/educat-character2',
        title: 'educat.courses',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'educat2',
    },

    {
        id: Date.now(),
        cover: '/img/portfolio/identity/high_liga/higeLiga00005.jpg',
        images: 'portfolio/identity/high-liga',
        title: 'Высшая лига. Промальп',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'high-liga-identity',
    },

]

