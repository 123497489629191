

export const dataSite = [

    {
        id: Date.now(),
        cover: '/img/portfolio/site/hige_liga/website_hl.jpg',
        images: 'portfolio/sites/highLiga',
        title: 'ligapromalp.com',
        description: `Задачи проекта: дизайн логотипа фирменного стиля, верстка документации. Разработка сайта под ключ — дизайн, frontend (React)`,
        link: 'ligapromalp.com',
        exLink: 'https://ligapromalp.com/'
    },
    {
        id: Date.now(),
        cover: '/img/portfolio/site/taboo/taboo3.jpg',
        images: 'portfolio/sites/taboo',
        title: 'Taboo',
        description: 'Проект остановили на стадии дизайна',
        link: 'taboo',
    },
    {
        id: Date.now(),
        cover: '/img/portfolio/site/cloud_change/cloud_change00000_cover.jpg',
        images: 'portfolio/sites/cloud-change',
        title: 'Сайты для скриптов',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'cloud-change',
    },
    {
        id: Date.now(),
        cover: '/img/portfolio/site/mafit/cover_mafit.jpg',
        images: 'portfolio/sites/mafit',
        title: 'UI фитнес приложения',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'mafit',
    },
    {
        id: Date.now(),
        cover: '/img/portfolio/site/finam/finam00003.jpg',
        images: 'portfolio/sites/finam',
        title: 'Сервис по поиску кредитов',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'finam',
    },
    {
        id: Date.now(),
        cover: '/img/portfolio/site/ehandy/enandy7.jpg',
        images: 'portfolio/sites/ehandy',
        title: 'ehandy',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'ehandy',
        exLink: 'https://ehandy.eu/'
        
    },

]
