import React, { useEffect, useState } from 'react';

const useLocalStorage = (initialValue, key) => {

    const getValue = () => {
        // обратись к локал сторадж и получи значение по ключу который пришел через параметр
        const storage = localStorage.getItem(key)
        if (storage) {
            return JSON.parse(storage)
        }
        return initialValue
    }

     const [value, setValue] = useState(getValue)

     useEffect (()=> {
        localStorage.setItem(key, JSON.stringify(value))
     })

     return [value, setValue]
}
export {useLocalStorage}