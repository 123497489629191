import { Routes, Route, useLocation } from "react-router-dom"

import { Projects } from "./Projects/Projects/Projects"
import { ProjectSinglepage } from './Projects/ProjectSinglepage/ProjectSinglepage'

import { dataLogotype } from './Projects/dataLogotype'
import { dataID } from './Projects/dataID'
import { dataPosters } from './Projects/dataPosters'
import { dataIllustration } from './Projects/dataIllustration'
import { dataPrint } from './Projects/dataPrint'
import { dataSite } from './Projects/dataSite'

import { ProjectModal } from "./Projects/ProjectModal/ProjectModal"
import { Contact } from "./Projects/Contact/Contact"
import { Login } from "./Authentication/Login"
import { Admin } from "./Admin/Admin"
import { AuthContextProvider } from "./Authentication/AuthContext"
import { RequireAuth } from "./Authentication/RequireAuth"
import { ProjectDataContextProvider } from "./Projects/useProjectData"
import { ProjectFilterCollectionContextProvider } from "./Projects/useProjectFilterCollection"
import { GridProjects } from "./components/Grid/Grid"
import { IncomingPage } from "./IncomingPage/IncomingPage"



function App() {

  // media
  const breakpoints = { mobile: 0, tablet: 768, desktop: 1600 };


  return (
    <>
      <AuthContextProvider>
        <ProjectDataContextProvider>
          <ProjectFilterCollectionContextProvider>
            <Routes >
              <Route path="/" element={<Projects />}>
                <Route path='all' element={<GridProjects />} />
                <Route path='identity' element={<GridProjects />} />
                <Route path='illustration' element={<GridProjects />} />
                <Route path='logotype' element={<GridProjects />} />
                <Route path='posters' element={<GridProjects />} />
                <Route path='print' element={<GridProjects />} />
                <Route path='uxui' element={<GridProjects />} />
              </Route>

              <Route path='all/:link' element={<ProjectSinglepage />} />
              <Route path='identity/:link' element={<ProjectSinglepage />} />
              <Route path='logotype/:link' element={<ProjectSinglepage />} />
              <Route path='posters/:link' element={<ProjectSinglepage />} />
              <Route path='illustration/:link' element={<ProjectSinglepage />} />
              <Route path='uxui/:link' element={<ProjectSinglepage />} />
              <Route path='print/:link' element={<ProjectSinglepage />} />

              <Route path='/contact' element={<Contact />} />
              <Route path='/login' element={<Login />} />
              <Route path='/admin' element={<RequireAuth><Admin /></RequireAuth>} />
              <Route path='admin/:link' element={<ProjectSinglepage />} />

            </Routes>
          </ProjectFilterCollectionContextProvider>
        </ProjectDataContextProvider>
      </AuthContextProvider>
    </>
  )
}


export default App;
