

import style from './AddProductForm.module.css'
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, CheckboxButton, Files, Input, UploadFile, UploadFiles } from '../unitForm/Input';
import { db, storage } from '../../../firebase-config';
import { addDoc, collection, getDoc, doc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import { useProjectFilterCollection } from '../../../Projects/useProjectFilterCollection';
import FirebaseData from '../../../Firebase/FirebaseData'


export const AddProductForm = ({ docId, setDocId }) => {

    const { projectFilterCollection } = useProjectFilterCollection()

    const [cover, setCover] = useState(null)
    const [coverURL, setCoverURL] = useState('')

    const [images, setImages] = useState([])
    const [imagesURL, setImagesURL] = useState([])

    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    const [category, setCategory] = useState([])
    const [client, setClient] = useState('')
    const [about, setAbout] = useState('')
    const [link, setLink] = useState('')
    const [exLink, setExLink] = useState('')
    const [oneColumn, setOneColumn] = useState(false)
    const [message, setMessage] = useState({ error: false, msg: '' })


    const changeCategory = ({ target: { checked, id } }) => {
        setCategory((!category.includes(id) && checked)
            ? [...category, id]
            : category.filter(cat => cat !== id)
        );
    };


    // ––––––––––––––––––––––––––––––– UPLOAD COVER


    const handleUploadFile = (e) => {
        const file = e.target.files[0]
        if (file) {
            setCover(file)
            setCoverURL(URL.createObjectURL(file))
        }
    }
    const uploadFile = (callback) => {
        if (cover == null) return;
        const storageRef = ref(storage, `projects/${Date.now()} + ${cover?.name}`) // ссылка на storage и папка
        const uploadTask = uploadBytesResumable(storageRef, cover)
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress)
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {

            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    callback(downloadURL)
                });
            }
        )
    }


    // ––––––––––––––––––––––––––––––– UPLOAD IMAGES


    const handleUploadFiles = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            const newFile = e.target.files[i]
            newFile['id'] = Math.random()
            setImages((prevState) => [...prevState, newFile])
        }
    }
    const uploadFiles = () => {
        images.map(file => {
            const storageRef = ref(storage, `projects/${Date.now()} + ${file.name}`) // ссылка на storage и папка
            const uploadTask = uploadBytesResumable(storageRef, file)
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progress)
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => { console.log(error) },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => setImagesURL((prevState) => [...prevState, downloadURL]))

                }

            )
        })
    }


    // ––––––––––––––––––––––––––––––– ADD PROJECT


    const newProject = (url) => {
        setLoading(true)
        addDoc(collection(db, 'projects'),
            {
                client,
                about,
                link,
                exLink,
                category,
                images: imagesURL,
                cover: url,
                oneColumn
            }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (imagesURL.length === images.length) {
            uploadFile((url) => {
                newProject(url)
            })
        }

    }, [imagesURL]);


    const handleSubmit = (e) => {
        e.preventDefault()
        uploadFiles()
        setSubmitted(true)

    }

    return (

        <form onSubmit={handleSubmit} >
            <div className={style.form}>

                <UploadFile
                    onChange={handleUploadFile}
                    url={coverURL}
                />
                <div className={style.categories}>
                    {projectFilterCollection.map(category => (
                        <CheckboxButton submitted={submitted}
                            key={category.id}
                            id={category.id}
                            value={category.value}
                            onChange={changeCategory}
                        />
                    ))}
                </div>

                <div className={style.inputs}>
                    <Input
                        id='client'
                        name='client'
                        placeholder='Клиент'
                        value={client}
                        onChange={e => setClient(e.target.value)}
                    />
                    <Input
                        id='about'
                        name='about'
                        placeholder='О проекте'
                        value={about}
                        onChange={e => setAbout(e.target.value)}
                    />
                    <Input
                        id='exlink'
                        name='exLink'
                        placeholder='Ссылка на проект'
                        value={exLink}
                        onChange={e => setExLink(e.target.value)}
                    />
                    <Input
                        id='link'
                        placeholder='link'
                        name='link'
                        value={link}
                        onChange={e => setLink(e.target.value)}
                    />
                    <Checkbox
                        id='column'
                        checked={oneColumn}
                        onChange={(e) => setOneColumn(e.target.checked)}
                        label = 'в 1 колонку'
                    />
                </div>

                <UploadFiles
                    onChange={handleUploadFiles}
                    images={images}
                    setImages={setImages}
                />

                <div className={style.buttonContainer}>
                    <button disabled={loading} className={style.buttonSubmut}><img src={submitted ? '/img/icon/buttonSubmit.png' : '/img/icon/buttonSubmitDefault.png'} /></button>
                </div>

            </div>
        </form >

    );
};
