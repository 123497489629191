import React from 'react';
import { Link } from 'react-router-dom';
import style from './footer.module.css'


export const Footer = () => {
    return (
        <footer>
            <div className={style.footer}>
                <Link to="/">
                    <img
                        className={style.logo}
                        src="/GraphicD_logo.png"
                        alt="" />
                </Link>
            </div>
        </footer>
    );
};

