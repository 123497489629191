import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';

import { collection, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase-config';

const CategoriesContext = createContext()

export const ProjectFilterCollectionContextProvider = ({ children }) => {

    const [projectFilterCollection, setProjectFilterCollection] = useState([])

    const getDoc = async () => {
        const querySnapshot = await getDocs(collection(db, 'categories'), orderBy('timestamp', 'desc'))
        let docs = []
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            docs.push({ ...doc.data(), id: doc.id })
        });
        setProjectFilterCollection(docs)
    }
    useEffect(() => {
        getDoc()
    }, [])


    const value = {
        projectFilterCollection
    }

    return (
        <CategoriesContext.Provider
            value={value}>
            {children}
        </CategoriesContext.Provider>
    )
};

export const useProjectFilterCollection = () => {
    return useContext(CategoriesContext)
}