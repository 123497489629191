import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import style from './SideBar.module.css'
import { Radio } from '../../components/Form/unitForm/Input'
import { useRef } from 'react';


export const SideBar = ({ onChange, projectFilterCollection, name, ...inputProps }) => {

    const categoryProject = projectFilterCollection.filter(category => category.id !== "identity")
    console.log (categoryProject)

    return (
        <>
            <div className={style.sideBar}>
                {categoryProject?.map((category) => (
                    <Radio key={category?.id}
                        onChange={onChange}
                        id={category?.id}
                        value={category?.value}
                        name={category?.name}
                        link={category?.link}

                    />
                ))}
            </div>
        </>

    );
};
