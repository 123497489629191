
import { useEffect, useState } from 'react';
import { collection, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase-config';


export function useGetDoc(collectionName) {

    const [docs, setDocs] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getDoc = async () => {
            try {
                const data = await getDocs(collection(db, collectionName), orderBy('date', 'desc'))
                setDocs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            } catch (e) {
                setError(e.message);
            }
        };
        getDoc();
    }, [collectionName]);

    return { docs, error };
}




// try {
//     const querySnapshot = await getDocs(collection(db, collectionName), orderBy('timestamp', 'desc'))
//     let docs = []
//     querySnapshot.forEach((doc) => {
//         // console.log(doc.id, " => ", doc.data());
//         docs.push({ ...doc.data(), id: doc.id })
//     });
//     setDocs(docs)
// } catch (e) {
//     setError(e.message);
// }