import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { getStorage } from "@firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCQQeN29xxyzJH0S2ZNAkiHGwFmWG2RgRg",
  authDomain: "graphicdigger-e23ed.firebaseapp.com",
  databaseURL: "https://graphicdigger-e23ed-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "graphicdigger-e23ed",
  storageBucket: "graphicdigger-e23ed.appspot.com",
  messagingSenderId: "562113029488",
  appId: "1:562113029488:web:d54fc43c1617deaf975bb5",
  measurementId: "G-71EFM9EXBV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth(app)