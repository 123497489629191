

export const dataPrint = [
    
    // savie:
    {
        id: Date.now(),
        cover: '/img/portfolio/print/sting/Be_01.jpg',
        images: 'portfolio/print/sting',
        title: 'Sting',
        description: 'Задача: 3D моделинг машинки. Дизайн и верстка инструкции.',
        link: 'sting',

    },
    {
        id: Date.now(),
        cover: '/img/portfolio/print/savie/savie0000.jpg',
        images:'portfolio/identity/savie',
        title: 'Savie. Салон красоты',
        description: 'Фирменный стиль и верстка журнала',
        link: 'savie-magazine',

    },
    {
        id: Date.now(),
        cover: '/img/portfolio/print/serdar/serdar00001.png',
        images:'portfolio/print/serdar-cambarov',
        title: 'Сердар Камбаров',
        description: 'Задача: разработать дизайн этикетки и упаковки для новой продукции',
        link: 'serdar-cambarov',

    },
    {
        id: Date.now(),
        cover:'/img/portfolio/print/contur/contur00000_cover.jpg',
        images: 'portfolio/illustration/contur',
        title: 'Contur',
        description: `Дизайн и верстка рабочей тетради для курсов.Векторная иллюстрация + инфографика`,
        link: 'contur',
    },
    {
        id: Date.now(),
        cover:'/img/portfolio/print/transSystem/calenedar_transsystem00000_cover.jpg',
        images: 'portfolio/illustration/calendar-transsystem',
        title: 'Транссистемы',
        description: 'Сайт портфолио graphicdigger.com на стадии разработки, описания проектов будут добавлены позже',
        link: 'transsystem',
    },
]
