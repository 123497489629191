
import React from 'react';
import style from './Contact.module.css'
import { Footer } from '../../components/Footer/footer';
import { Header } from '../../components/Header/header';

export const Contact = () => {
    return (
        <>
            <Header />
            <main className={style.main}>
                <div className={style.content}>
                    <div className={style.photo}><img src="/img/photo/production 3.jpg" /></div>
                    <p>Если вы ищете дизайнера, который поможет воплотить идею в жизнь, и у вас сжатые сроки — давайте начнем!</p>
                    <div className={style.skills}>
                        <div className={style.skill}>UX/UX дизайн</div>
                        <div className={style.skill}>Графический дизайн</div>
                        <div className={style.skill}>Инфографика</div>
                        <div className={style.skill}>Иллюстрация</div>
                        <div className={style.skill}>Верстка сайта</div>


                    </div>
                    <div className={style.contact}>
                        <a href='https://wa.me/79279679700'>WhatsApp</a>
                        <a href='https://t.me/graphicdigger'>Telegram</a>
                        <p>ilmirdesigner@gmail.com</p>
                    </div>


                </div>

            </main>
            <Footer />
        </>
    );
};
