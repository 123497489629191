import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/Header/header';
import { useAuth } from './AuthContext';

export const Login = () => {

    const { authGoogle } = useAuth()
    const navigate = useNavigate()
    const [error, setError] = useState()

    const handleAuthGoogle = async (e) => {
        e.preventDefault()
        try {
            await authGoogle()
            navigate('/')

        } catch (error) {
            setError(error.message)
        }
    }

    return (
        <>
            <Header />

            <img onClick={handleAuthGoogle} src="/img/icon/Google.png" style={{ width: '24px', margin: '50px auto', cursor: 'pointer' }} />
        </>
    );
};
