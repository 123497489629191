import { db } from "../firebase-config"
import {
    collection,
    getDocs,
    getDoc,
    updateDoc,
    deleteDoc,
    addDoc,
    doc,
    orderBy,
} from 'firebase/firestore'


class FirebaseData {

    addProjects = (newProject) => {
        return addDoc(collection(db, 'projects'), newProject)
    }
    getAllProjects = () => {
        return getDocs(collection(db, 'projects'))
    }

    updateProject = (id, projectUpdate) => {
        const document = doc(db, 'projects', id)
        return updateDoc(document, projectUpdate)
    }
    deleteProject = (id) => {
        const document = doc(db, 'projects', id)
        return deleteDoc(document)
    }
    getProject = (id) => {
        const document = doc(db, 'projects', id)
        return getDoc(document)
    }

}

export default new FirebaseData()